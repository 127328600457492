<template>
  <el-scrollbar height="calc(100vh - 3.5rem)">
    <div class="flex-def">
      <slot name="left"></slot>
      <div style="width: 100%">
        <div style="min-height: calc(100vh - 9rem);padding: 1.25rem">
          <slot></slot>
        </div>
        <y_layout_foot></y_layout_foot>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import y_layout_foot from "@/components/common/y_layout_foot";
export default {
  name: "y_layout_main",
  components:{y_layout_foot},
  data(){
    return{
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="flex-def flex-cCenter flex-zBetween" style="height: 100%;">
    <div style="font-weight: 600">{{ hello_tips }}</div>
    <y_layout_head_right_menu></y_layout_head_right_menu>
  </div>
</template>

<script>
import y_layout_head_right_menu from "@/components/common/y_layout_head_right_menu";
export default {
  name: "y_layout_head",
  components:{
    y_layout_head_right_menu
  },
  data(){
    return{
      hello_tips: "",
    }
  },
}
</script>

<style scoped>

</style>
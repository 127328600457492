import { render, staticRenderFns } from "./y_layout_head.vue?vue&type=template&id=ba59b46a&scoped=true&"
import script from "./y_layout_head.vue?vue&type=script&lang=js&"
export * from "./y_layout_head.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba59b46a",
  null
  
)

export default component.exports
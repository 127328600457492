<template>
  <div>
    <el-button @click="toManager" v-if="$store.state.sys.account.info.is_founder" style="margin-right: 2rem" type="text"  size="mini">
      平台管理
    </el-button>
    <el-dropdown @command="topTap">
      <span class="y-pointer el-dropdown-link account-top">
        {{$store.state.sys.account.info.username}}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="account">我的账号</el-dropdown-item>
          <el-dropdown-item command="logout">退出登陆</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "y_layout_head_right_menu",
  data() {
    return {}
  },
  methods: {
    topTap(e) {
      this[e] && this[e]();
    },
    toManager(){
      location.href = location.origin + "/#/manager/sys"
      // this.$router.toManagerPage();
    },
    account() {
      this.$router.toAccountPage();
    },
    ...mapActions("sys/account", [
      "logout"
    ])
  }
}
</script>

<style scoped>
.account-top:hover {
  color: #5D60F6;
  font-weight: 600;
}
</style>